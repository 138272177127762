// Core React
import Layout from "@components/layout/Layout/Layout"
import MintPage from "@partials/MintPage/MintPage"
import React from "react"

const IndexPage = () => (
  <Layout
    title="Masterbrews | NFT Sale"
    contentClassName="homePageContent px-0 max-w-full"
  >
    <MintPage />
  </Layout>
)
export default IndexPage
